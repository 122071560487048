<div class="p-grid">
    <div class="p-col-1"></div>
    <div class="p-col-10">
        <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
                <button pButton pRipple label="{{'BACK' | translate}}" class="p-button-success p-mr-2 p-mb-2"
                    (click)="goToBack()"></button>
            </ng-template>
        </p-toolbar>
    </div>
    <div class="p-col-1"></div>
</div>
<div class="p-grid">
    <div class="p-col-1"></div>
    <div class="p-col-6">
        <p-toast></p-toast>
        <div class="card">
            <div class="p-fluid">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                        {{'PROFILE_DATA'|translate}}
                    </ng-template>
                </p-toolbar>
                <input style="display:none" type="file" #fileUpload id="fileUpload" name="fileUpload"
                    (change)="upload(fileUpload.files)" accept="image/*" />
                <form [formGroup]="editForm">
                    <input type="hidden" id="profileImage" formControlName="profileImage" />

                    <div class="p-formgrid p-grid p-ai-center vertical-container">
                        <div class="p-field p-col-fixed" style="width: 64px;">
                            <p-avatar image="{{pImage}}" size="xlarge" styleClass="p-mr-2" shape="circle"
                                (click)="openFileUpload()"></p-avatar>
                        </div>
                        <div class="p-field p-col">
                            <label for="userName">{{ 'USER_NAME' | translate }}</label>
                            <input type="text" pInputText id="userName" formControlName="userName" required autofocus />
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">User name is required.</small>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-4">
                            <label for="lastName">{{ 'LAST_NAME' | translate }}</label>
                            <input type="text" pInputText id="lastName" formControlName="lastName" required />
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">Last name is required.</small>
                        </div>
                        <div class="p-field p-col-4">
                            <label for="firstName">{{ 'FIRST_NAME' | translate }}</label>
                            <input type="text" pInputText id="firstName" formControlName="firstName" required />
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">First name is
                                required.</small>
                        </div>
                        <div class="p-field p-col-4">
                            <label for="patronymic">{{ 'PATRONYMIC' | translate }}</label>
                            <input type="text" pInputText id="patronymic" formControlName="patronymic" />
                        </div>
                    </div>

                    <div class="p-formgrid p-grid">
                        <div class="p-field  p-col-6">
                            <label for="phoneNumber">{{ 'PHONE_NUMBER' | translate }}</label>
                            <p-inputMask inputId="phoneNumber" formControlName="phoneNumber" mask="99 999 99 99"
                                required>
                            </p-inputMask>
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">Phone number is
                                required.</small>
                        </div>
                        <div class="p-field  p-col-6">
                            <label for="email">{{ 'EMAIL' | translate }}</label>
                            <input type="email" pInputText id="email" formControlName="email" required />
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">Email is required.</small>
                        </div>
                    </div>
                    <div class="p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="birthDate">{{ 'BIRTH_DATE' | translate }}</label>
                            <p-calendar formControlName="birthDate" dateFormat="dd.mm.yy" [monthNavigator]="true"
                                [yearNavigator]="true" yearRange="1930:2030" required></p-calendar>
                            <small class="p-invalid" *ngIf="submitted && !editForm.valid">Birth date is
                                required.</small>
                        </div>
                    </div>
                    <button pButton pRipple label="{{'SAVE' | translate}}" class="p-button-success p-mr-2 p-mb-2"
                        (click)="save()"></button>
                </form>
            </div>
        </div>
    </div>
    <div class="p-col-4">
        <p-toast></p-toast>
        <div class="card">
            <div class="p-fluid">
                <p-toolbar styleClass="p-mb-4">
                    <ng-template pTemplate="left">
                        {{'CHANGE_PASSWORD'|translate}}
                    </ng-template>
                </p-toolbar>
                <form [formGroup]="passwordForm">
                    <div class="p-field">
                        <label for="currentPassword">{{ 'CURRENT_PASSWORD' | translate }}</label>
                        <input type="password" pInputText id="currentPassword" formControlName="currentPassword"
                            required />
                        <small class="p-invalid" *ngIf="passwordSubmitted && !passwordForm.valid">Current password is
                            required.</small>
                    </div>
                    <div class="p-field">
                        <label for="newPassword">{{ 'NEW_PASSWORD' | translate }}</label>
                        <input type="password" pInputText id="newPassword" formControlName="newPassword" required />
                        <small class="p-invalid" *ngIf="passwordSubmitted && !passwordForm.valid">New password is
                            required.</small>
                    </div>
                    <div class="p-field">
                        <label for="confirmNewPassword">{{ 'CONFIRM_PASSWORD' | translate }}</label>
                        <input type="password" pInputText id="confirmNewPassword" formControlName="confirmNewPassword"
                            required />
                        <small class="p-invalid" *ngIf="passwordSubmitted && !passwordForm.valid">Confirm password is
                            required.</small>
                    </div>
                    <button pButton pRipple label="{{'CHANGE_PASSWORD' | translate}}"
                        class="p-button-success p-mr-2 p-mb-2" (click)="changePassword()"></button>
                </form>
            </div>
        </div>
    </div>
    <div class="p-col-1"></div>
</div>