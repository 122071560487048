<div class="layout-sidebar" (click)="appMain.onMenuClick($event)">
    <a [routerLink]="['/']" class="logo">
        <img id="app-logo" [src]="orgInfo ? orgInfo.logoImage : 'assets/layout/images/dentos_logo_white.png'" width="180px" />
    </a>
    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [appMain]="appMain" [item]="item" [index]="i" [root]="true">
                </li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>
</div>