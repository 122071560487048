<div class="login-body">
    <div class="login-wrapper">
        <p-toast></p-toast>
        <div class="login-panel">
            <img [src]="'assets/layout/images/dentos_logo_'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.png'"
                class="logo" alt="diamond-layout" />
            <form [formGroup]="loginForm">
                <div class="login-form">
                    <h4>{{'ENTER_TO_YOUR_ACCOUNT'|translate}}</h4>
                    <input pInputText id="userName" formControlName="userName" placeholder="{{'USER_NAME'|translate}}" required />
                    <input pPassword type="password" id="password" formControlName="password" placeholder="{{'PASSWORD'|translate}}" [feedback]="false" required />
                    <button pButton label="{{'LOGIN'|translate}}" type="submit" (click)="onLogin()" [disabled]="!loginForm.valid"></button>
                </div>
            </form>
            <div class="login-form">

            </div>
            <div class="login-form">

            </div>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h3>{{'INFORMATION_SYSTEM_FOR_DENTAL CLINICS'|translate}}</h3>
                <h1>{{'ACCESS_TO_YOUR_ACCOUNT'|translate}}</h1>
            </div>
        </div>
    </div>
</div>