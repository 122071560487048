import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../core/services';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
})
export class AppMainComponent implements OnInit {

    constructor(private userService: UserService, private router: Router) {
    }
    ngOnInit(): void {
        if (this.userService.HasRole('admin')) {
            this.router.navigateByUrl('/dashboard/admin');
        } else if (this.userService.HasRole('doctor')) {
            this.router.navigateByUrl('/dashboard/doctor');
        }
        else if (this.userService.HasRole('reception')) {
            this.router.navigateByUrl('/dashboard/reception');
        }
        else if (this.userService.HasRole('cashier') || this.userService.HasRole('supervisor')) {
            this.router.navigateByUrl('/dashboard/cashier');
        }
        else if (this.userService.HasRole('rentgen')) {
            this.router.navigateByUrl('/dashboard/rentgen');
        }
        else {
            this.router.navigateByUrl('/login');
        }
    }
}
